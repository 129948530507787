import { SlotLabelContentArg } from "@fullcalendar/core"
import { useTranslation } from "react-i18next"
import { CustomSlotLabelCalendarWrapper } from "./styles"

interface IProps {
	info: SlotLabelContentArg
}
const isbeforetoday = (date: Date) => {
	const today = new Date()
	today.setHours(0, 0, 0, 0)
	return date <= today // Return true if date is before today
}

const istoday = (date: Date) => {
	const today = new Date()
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	)
}
export default function CustomSlotLabelCalendar({ info }: IProps) {
	const {
		i18n: { language },
	} = useTranslation()
	const date = info.date
	const numberOfDays = `${date.getUTCDate()}`
	const options: Intl.DateTimeFormatOptions = { weekday: "long" } // Set options to get long weekday name
	const dayName = new Intl.DateTimeFormat(language, options).format(date) // Get the day name

	return (
		<CustomSlotLabelCalendarWrapper
			$isbeforetoday={isbeforetoday(date)}
			$istoday={istoday(date)}
		>
			<p>{dayName}</p>
			<p>
				<span>{numberOfDays}</span>
			</p>
		</CustomSlotLabelCalendarWrapper>
	)
}

import FullCalendar from "@fullcalendar/react"
import { MutableRefObject } from "react"
import { GrNext } from "react-icons/gr"
import { GoToPrevWrapper } from "./styles"

interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
}
export default function GoToPrev({ calendarRef }: IProps) {
	const goToPrev = () => {
		const calendarApi = calendarRef.current?.getApi()
		if (calendarApi) {
			calendarApi.prev() // Navigate to the next period
		}
	}
	return (
		<GoToPrevWrapper>
			<button onClick={goToPrev}>
				<GrNext />
			</button>
		</GoToPrevWrapper>
	)
}

import { ColCellContentArg } from "@fullcalendar/resource"
import { CustomViewPropertiesWrapper } from "./styles"
interface IProps {
	arg: ColCellContentArg
}
export default function CustomViewProperties({ arg }: IProps) {
	return (
		<CustomViewPropertiesWrapper>
			<div className='containerImage'>
				{arg?.resource?.extendedProps?.image ? (
					<img src={arg?.resource?.extendedProps?.image} alt='image' />
				) : (
					""
				)}
			</div>
			<div className='containerData'>
				<p>{arg?.resource?.extendedProps?.title}</p>
				<p>{arg?.resource?.extendedProps?.description}</p>
			</div>
		</CustomViewPropertiesWrapper>
	)
}

import { useTranslation } from "react-i18next"
import { ManualReservationWrapper } from "./styles"

export default function ManualReservation() {
	const { t } = useTranslation()
	return (
		<ManualReservationWrapper>
			<button>{t("MANUALRESERVATION.MANUALRESERVATION")}</button>
		</ManualReservationWrapper>
	)
}

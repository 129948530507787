import styled from "styled-components"
interface ButtonProps {
	children: React.ReactNode // Make sure to accept children if needed
}
export const ManualReservationWrapper = styled.div`
	button {
		color: #fff;
		border: none;
		background-color: #5b3fff;
		padding: 14px 24px;
		border-radius: 12px;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		text-align: center;
	}
`
export const TodayWrapper = styled.div`
	button {
		color: #5b3fff;
		border: 1px solid #5b3fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		text-align: center;
		background-color: #fff;
		border-radius: 12px;
		padding: 14px 24px;
	}
`
export const GoToPrevWrapper = styled.div<ButtonProps>`
	button {
		width: 52px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		border: 1px solid #eaedf1;
		background-color: #fff;
		color: #5b3fff;
	}
`
export const GoToNextWrapper = styled(GoToPrevWrapper)<ButtonProps>``

import { IoInformationCircleOutline } from "react-icons/io5"
import { ContainerCardWrapper } from "./styles"
import { useReservations } from "@services"
import { useTranslation } from "react-i18next";

export default function CardsInfo() {
	const { ReservationCardQuery } = useReservations()
	const { data: ReservationCard } = ReservationCardQuery();
	const {t} =useTranslation();

	const data = [
		{
			title: t('Reservation.NumBookings'),
			count: ReservationCard?.totalReservationsCount,
			info: "",
		},
		{
			title: t('Reservation.ManualBookings'),
			count: ReservationCard?.manualReservationsCount,
			info: "",
		},
		{
			title: t('Reservation..AutomatedBookings'),
			count: ReservationCard?.automaticReservationsCount,
			info: "",
		},
		{
			title: t('Reservation.PendingBookings'),
			count: ReservationCard?.pendingReservationsCount,
			info: "",
		},
		{
			title: t('Reservation.CancelledBookings'),
			count: ReservationCard?.cancelledReservationsCount,
			info: "",
		},
	]

	return (
		<ContainerCardWrapper>
			{data.map((item) => {
				return (
					<div className='containerCard'>
						<div>
							<p>{item.title}</p>
							<div>
								<IoInformationCircleOutline />
							</div>
						</div>
						<div>
							<p>{item.count}</p>
						</div>
					</div>
				)
			})}
		</ContainerCardWrapper>
	)
}
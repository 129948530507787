import { useState } from "react"
import { PaginationPropertiesWrapper } from "./styles"
import { GrNext, GrPrevious } from "react-icons/gr"

interface IProps {
	lengthOfRecords: number
}
export default function PaginationProperties({ lengthOfRecords }: IProps) {
	const [currentPage, setCurrentPage] = useState(1) // State to track current page
	const resourcesPerPage = 13 // Number of resources per page
	const totalPages = Math.ceil(lengthOfRecords / resourcesPerPage) // Total pages

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
		}
	}
	return (
		<PaginationPropertiesWrapper>
			<div>
				<button onClick={handlePreviousPage} disabled={currentPage === 1}>
					<GrNext />
				</button>
				<span>
					{currentPage} / {totalPages}
				</span>
				<button onClick={handleNextPage} disabled={currentPage === totalPages}>
					<GrPrevious />
				</button>
			</div>
		</PaginationPropertiesWrapper>
	)
}

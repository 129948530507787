import { useTranslation } from "react-i18next"
import { CustomSearchInputWrapper } from "./styles"
import { FormProvider, useForm } from "react-hook-form"
import { IoIosSearch } from "react-icons/io"
interface IForm {
	search: string
}
export default function CustomSearchInput() {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const methods = useForm<IForm>()
	const { register } = methods
	const onSubmit = async (value: IForm) => {
		console.log(value)
	}
	return (
		<CustomSearchInputWrapper lang={language === "ar" ? "ar" : "en"}>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<div className='inputcontainer'>
						<input
							autoComplete='off'
							type='search'
							placeholder={t("COMMON.SEARCH_PROPERY")}
							{...register("search")}
						/>
						<div className='search'>
							<IoIosSearch />
						</div>
					</div>
				</form>
			</FormProvider>
		</CustomSearchInputWrapper>
	)
}

import styled from "styled-components"

export const CustomHeaderWrapper = styled.div`
	border: 1px solid #e8e8e8;
	background-color: #fff;
	padding: 20px 30px;
	.containerLabel {
		p {
			margin: 0;
			&:first-child {
				color: #130080;
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
			}
			&:last-child {
				color: #adbac7;
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
			}
		}
	}

	.switchActions {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		.switchBtweenWeeks {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
			p {
				margin: 0;
				color: #8e98a1;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				text-align: center;
			}
		}
	}
`

import { FormProvider, useForm } from "react-hook-form"
import HeaderSection from "../headerSection"
import { useTranslation } from "react-i18next"
import CustomSelect from "@Common/CustomSelect"
import { Grid } from "@mui/material"
import CustomDatePicker from "@Common/CustomDatePicker"
import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import Input from "@Common/input"
import { useCalendar, useProperty } from "@services"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(isBetween)
export interface IFormPropertyInfoFormInputs {
	propertyId: string
	propertyName: string
	checkIn: string
	checkOut: string
	checkInTime: string
	checkOutTime: string
}
interface IProps {
	setIsActiveStep: Dispatch<SetStateAction<number>>
	isActiveStep: number
	setPropertyData: Dispatch<
		SetStateAction<IFormPropertyInfoFormInputs | undefined>
	>
	getPropertyData: IFormPropertyInfoFormInputs | undefined
}
export default function PropertyInfoFormInputs({
	setIsActiveStep,
	isActiveStep,
	setPropertyData,
	getPropertyData,
}: IProps) {
	/* ---------------------------------- Hooks --------------------------------- */
	const { getManagementListQuery } = useProperty()
	const { data: propertiesList } = getManagementListQuery()
	const { t } = useTranslation()
	const { getAllCalendarQuery } = useCalendar()
	const { data: allCalendar } = getAllCalendarQuery(null)

	const propertyList = useMemo(() => {
		const listOfData = propertiesList?.map((item: any) => {
			return {
				label: item.propertyName,
				value: item.propertyId.toString(),
			}
		})
		return listOfData ?? []
	}, [propertiesList])
	const methods = useForm<IFormPropertyInfoFormInputs>()
	const { setValue, watch } = methods
	const propertyIdWatch = watch("propertyId")
	const onSubmit = (value: IFormPropertyInfoFormInputs) => {
		const data = {
			propertyId: value.propertyId,
			propertyName: propertyList?.filter(
				(item: any) => item.value === value.propertyId
			)[0].label,
			checkIn: value.checkIn,
			checkOut: value.checkOut,
			checkInTime: value.checkInTime,
			checkOutTime: value.checkOutTime,
		}
		setPropertyData(data)
		setIsActiveStep(isActiveStep + 1)
	}

	const disabledRanges = useMemo(() => {
		const allDays = allCalendar?.filter(
			(item: any) => item.propertyId.toString() === propertyIdWatch
		)
		const UnavilabelDates = allDays?.[0]?.dates?.filter(
			(item: any) => item.availability === "unavailable"
		)
		const disabledDates = UnavilabelDates?.map((item: any) => {
			return {
				from: item.date,
				to: item.date,
			}
		})
		let filteredArr = disabledDates?.filter((obj: any) => obj.from && obj.to)
		return filteredArr
	}, [allCalendar, propertyIdWatch])

	useEffect(() => {
		disabledRanges
	}, [disabledRanges])
	const shouldDisableDate = (date: any) => {
		const isInDisabledRange = disabledRanges?.some((range: any) =>
			date.isBetween(dayjs(range.from), dayjs(range.to), "day", "[]")
		)
		return isInDisabledRange
	}
	useEffect(() => {
		if (getPropertyData) {
			setValue("checkIn", getPropertyData.checkIn)
			setValue("checkInTime", getPropertyData.checkInTime)
			setValue("checkOut", getPropertyData.checkOut)
			setValue("checkOutTime", getPropertyData.checkOutTime)
			setValue("propertyId", getPropertyData.propertyId)
		}
	}, [getPropertyData])
	return (
		<div>
			<HeaderSection label={t("MANUALRESERVATION.PROPERTYINFORMATION")} />
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmit)}
					id='FormManualReservation'
				>
					<Grid container spacing={"24px"}>
						<Grid item xs={12}>
							<CustomSelect
								data={propertyList}
								name='propertyId'
								label={t(
									"MANUALRESERVATION.SELECTTHEPROPERTYFORWHICHYOUWANTTOCREATEARESERVATION"
								)}
								defaultValue={getPropertyData?.propertyId}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASESELECTTHEPROPERTYFROMTHEDROPDOWNLIST"
										),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<HeaderSection
								label={t("MANUALRESERVATION.TIMEANDDATEOFRESERVATION")}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomDatePicker
								disablePast
								name='checkIn'
								label={t("MANUALRESERVATION.BOOKINGSTARTDATE")}
								format='YYYY-MM-DD'
								disabled={!propertyIdWatch}
								shouldDisableDate={shouldDisableDate}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASESELECTTHESTARTDATEOFTHERESERVATION"
										),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Input
								type='time'
								name='checkInTime'
								label={t("MANUALRESERVATION.BOOKINGSTARTTIME")}
								disabled={!propertyIdWatch}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASESELECTTHESTARTTIMEOFTHERESERVATION"
										),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomDatePicker
							disablePast
								name='checkOut'
								label={t("MANUALRESERVATION.BOOKINGENDDATE")}
								format='YYYY-MM-DD'
								disabled={!propertyIdWatch}
								shouldDisableDate={shouldDisableDate}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASESELECTTHEENDDATEOFTHERESERVATION"
										),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Input
								type='time'
								name='checkOutTime'
								disabled={!propertyIdWatch}
								label={t("MANUALRESERVATION.BOOKINGENDTIME")}
								registerOptions={{
									required: {
										value: true,
										message: t(
											"MANUALRESERVATION.PLEASESELECTTHEENDTIMEOFTHERESERVATION"
										),
									},
								}}
							/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</div>
	)
}

import FullCalendar from "@fullcalendar/react"
import { MutableRefObject } from "react"
import { TodayWrapper } from "./styles"
import { useTranslation } from "react-i18next"

interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
}
export default function GoToday({ calendarRef }: IProps) {
	const { t } = useTranslation()
	const goToday = () => {
		const calendarApi = calendarRef.current?.getApi()
		if (calendarApi) {
			const today = new Date()
			calendarApi.gotoDate(today) // Navigate to current day
		}
	}
	return (
		<TodayWrapper>
			<button onClick={goToday}>{t("COMMON.TODAY")}</button>
		</TodayWrapper>
	)
}

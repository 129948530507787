import { MutableRefObject } from "react"
import { CustomHeaderWrapper } from "./styles"
import FullCalendar from "@fullcalendar/react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import ManualReservation from "./actions/manualReservation"
import GoToPrev from "./actions/goToPrev"
import GoToNext from "./actions/goToNext"
import GoToday from "./actions/goToday"
interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
}
export default function CustomHeader({ calendarRef }: IProps) {
	/* ---------------------------------- Hooks --------------------------------- */
	const { t } = useTranslation()
	return (
		<CustomHeaderWrapper>
			<Grid container justifyContent='flex-end'>
				<Grid item xs={12} md={3}>
					<div className='containerLabel'>
						<p>{t("CALENDAR.CALENDAR")}</p>
						<p>{t("CALENDAR.A_SPACE_TO_HELP_YOU_REVIEW_YOUR_RESERVATIONS")}</p>
					</div>
				</Grid>
				<Grid item xs={12} md={3}>
					<ManualReservation />
				</Grid>
				<Grid item xs={12} md={6}>
					<div className='switchActions'>
						<GoToday calendarRef={calendarRef} />
						<div className='switchBtweenWeeks'>
							<GoToPrev calendarRef={calendarRef} />
							<p>الاسبوع</p>
							<GoToNext calendarRef={calendarRef} />
						</div>
					</div>
				</Grid>
			</Grid>
		</CustomHeaderWrapper>
	)
}

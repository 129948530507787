import styled from "styled-components"

export const CalendarComponentsWrapper = styled.div`
	.fc-resource-timeline-divider {
		pointer-events: none;
	}

	.fc-highlight {
		background-color: #5b3fff;
		color: #fff;
		border-radius: 5px;
	}
	/* .fc-timeline-event:not(.fc-event-end) {
		> div {
			> div {
				border-radius: 0 34px 34px 0 !important;
			}
		}

		&::after {
			display: none;
		}
	}
	.fc-timeline-event:not(.fc-event-start) {
		> div {
			> div {
				border-radius: 34px 0 0 34px !important;
			}
		}
		&::before {
			display: none;
		}
	} */

	.fc-datagrid-body.fc-scrollgrid-sync-table {
		background-color: #fff;
		tbody {
			tr {
				td {
					border: 1px solid #f6f6f7 !important;
				}
			}
		}
	}
	.fc-datagrid-header {
		.fc-datagrid-cell-frame {
			width: 100%;
			display: block;
		}
	}

	.fc-timeline-slot-frame {
		width: 100%;
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
`

import { EventInput } from "@fullcalendar/core"
import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction" // for drag and drop, click, etc.
import resourceTimelinePlugin from "@fullcalendar/resource-timeline" // needed for dayClick
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { getCurrentWeekVisibleRange } from "./functions/CurrentWeekVisibleRange"
import { CalendarComponentsWrapper } from "./styles"
import CustomViewProperties from "./components/customViewProperties"
import CustomSearchInput from "./components/customSearchInput"
import PaginationProperties from "./components/paginationProperties"
import EventContent from "./components/eventContent"
import CustomHeader from "./components/customHeader"
import CustomSlotLabelCalendar from "./components/customSlotLabelCalendar"

export default function NewCalendarComponents() {
	/* ------------------------------- local State ------------------------------ */
	const [events] = useState<EventInput[]>([
		{
			start: "2024-09-30T12:00",
			end: "2024-10-02T12:00",
			resourceId: "406",
			extendedProps: {
				id: "1",
				title: "Meeting",
				icon: "Airbnb",
				status: "yellow",
			},
		},
		{
			resourceId: "406",
			start: "2024-09-25T12:00",
			end: "2024-09-26T12:00",
			extendedProps: {
				id: "2",
				icon: "GatherIn",
				title: "Workshop",
				status: "gray",
			},
		},
		{
			resourceId: "407",
			start: "2024-09-26T12:30:00",
			end: "2024-09-29T12:30:00",
			extendedProps: {
				id: "2",
				icon: "Booking",
				title: "Workshop",
				status: "green",
			},
		},
	])

	const resources = [
		{
			id: "406",
			extendedProps: {
				title: "Room A",
				description: "Capacity: 20 people",
				image:
					"https://media.istockphoto.com/id/645239482/photo/your-new-house.jpg?s=1024x1024&w=is&k=20&c=txbsqhT0ZpvVFaOY7K1bR44oBGWQ6_SQuBEcBeBNMrM=",
			},
		},
		{
			id: "407",
			extendedProps: {
				title: "Room B",
				description: "Capacity: 50 people",
				image:
					"https://media.istockphoto.com/id/645239482/photo/your-new-house.jpg?s=1024x1024&w=is&k=20&c=txbsqhT0ZpvVFaOY7K1bR44oBGWQ6_SQuBEcBeBNMrM=",
			},
		},
	]

	/* ---------------------------------- Hooks --------------------------------- */
	const calendarRef = useRef<FullCalendar | null>(null)

	const {
		i18n: { language },
	} = useTranslation()

	return (
		<CalendarComponentsWrapper>
			<CustomHeader calendarRef={calendarRef} />

			<FullCalendar
				ref={calendarRef}
				direction={language === "ar" ? "rtl" : "ltr"}
				plugins={[resourceTimelinePlugin, interactionPlugin]}
				initialView='resourceTimelineWeek' // Displays the week in resource timeline
				visibleRange={getCurrentWeekVisibleRange()} // Displays current week's range (Monday-Sunday)
				resources={resources} // all properties
				events={events}
				resourceAreaWidth={200} // Set a fixed width for the resource column
				slotLabelFormat={{ weekday: "long", day: "numeric" }} // Displays days in the header
				slotLabelContent={(info) => <CustomSlotLabelCalendar info={info} />}
				slotDuration='24:00' // Each slot is 1 day
				height='auto'
				headerToolbar={false} // Hide default header
				eventContent={(arg) => (
					<EventContent
						status={
							arg?.event.extendedProps?.status as "yellow" | "gray" | "green"
						}
						title={arg?.event.extendedProps?.title}
						channal={
							arg?.event.extendedProps?.icon as
								| "Airbnb"
								| "GatherIn"
								| "Booking"
								| "Agoda"
								| "Msool"
						}
					/>
				)} //Event Content
				eventColor='transparent' // Global background color for all events
				resourceAreaColumns={[
					{
						headerContent: () => <CustomSearchInput />, // Custom search input
						cellContent: (arg) => <CustomViewProperties arg={arg} />, // Custom view properties
					},
				]}
				selectable={true} // Enable selection
				// slotLaneContent={<div>200</div>} // Customize resource lane content
			/>
			<PaginationProperties lengthOfRecords={resources.length} />
		</CalendarComponentsWrapper>
	)
}

import styled from "@emotion/styled"

export const EventContentWrapper = styled.div<{
	status: "yellow" | "gray" | "green"
}>`
	border: 1px solid #000;
	background-color: ${(props) =>
		props.status === "yellow"
			? "#FFF7E1"
			: props.status === "gray"
				? "#E2E2E2"
				: props.status === "green"
					? "#D5F7D8"
					: "#FFF7E1"};
	border: ${(props) =>
		props.status === "yellow"
			? "1px solid #FFE9A9"
			: props.status === "gray"
				? "1px solid #C4C4C4"
				: props.status === "green"
					? "1px solid #B6E5BB"
					: "1px solid #FFE9A9"};
	border-radius: 34px;
	color: #435363;
	.custom-event {
		padding: 6px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 6px;
		.containerIcon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		.containerContent {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`

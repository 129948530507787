import {
	Box,
	Button,
	Collapse,
	Drawer,
	IconButton,
	Toolbar,
	Typography,
} from "@mui/material"
import {
	CalendarToday as CalendarIcon,
	CardMembership as CardMembershipIcon,
	BarChart as BarChartIcon,
	CreditCard as CreditIcon,
	ExpandLess,
	ExpandMore,
	FormatListBulleted as ListIcon,
	Key as KeyIcon,
	Logout as LogoutIcon,
	MailOutline,
	MoreVert as MoreIcon,
	Settings as SettingsIcon,
	PeopleAltOutlined

} from "@mui/icons-material"
import ReserveIcon from "@assets/svg/reserve.svg?react"
import TuneIcon from "@mui/icons-material/Tune"
import useBreakPoinst from "@hooks/useBreakPoinst"
import Trail from "@assets/svg/trailInfo.svg?react"
import CloseIcon from "@assets/svg/close-cross.svg?react"
import BlackCloseIcon from "@assets/svg/close-cross-black.svg?react"
import BlackInfo from "@assets/svg/blackInfo.svg?react"
import {
	SupscriptionModal as SupscriptionPlaneModal,
	SubscriptModal,
	ThreeDaysToSupscripeModal,
} from "@components"

import {
	NavLink,
	Outlet,
	useLocation,
	useNavigate,
	createSearchParams,
} from "react-router-dom"
import { FC, ReactNode, Suspense, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Logo } from "../../utils/image-consts"
import { useAuth } from "@services"
import { disabledFeatures } from "@utils"

const drawerWidth = 240

const Layout = () => {
	const { pathname } = useLocation()
	const [showSupscriptionModal, setShowSupscriptionModal] = useState(false)
	const [showSubscibeModal, setShowSubscibeModal] = useState(false)
	const [showTrialTip, setShowTrialTip] = useState(false)
	const [showThreeDaysModal, setShowThreeDaysModal] = useState(false)

	const { isPhone } = useBreakPoinst()

	const { logout } = useAuth()
	const active = useMemo(() => pathname?.split("/")[1], [pathname])
	const subActive = useMemo(() => pathname?.split("/")[2], [pathname])
	const {
		i18n: { language, changeLanguage },
		t,
	} = useTranslation()
	const [mobileOpen, setMobileOpen] = useState(false)
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}
	const [menuOpen, setMenuOpen] = useState<string | undefined>(active)
	const navigate = useNavigate()
	const { getUserInfo } = useAuth()
	const user = getUserInfo()
	const isThereeDaysOrLessLeft = user?.trialDays <= 3 && user?.trialDays > 0

	useEffect(() => {
		if (user?.trialDays == null) return
		if (isThereeDaysOrLessLeft) {
			setShowThreeDaysModal(true)
		} else {
			setShowThreeDaysModal(false)
		}
	}, [isThereeDaysOrLessLeft])

	useEffect(() => {
		if (user?.trialDays == null) return
		if (user?.trialDays > 0) {
			return setShowTrialTip(true)
		} else {
			setShowTrialTip(false)
		}
		if (user?.trialDays <= 0) {
			setShowSubscibeModal(true)
		}
	}, [user?.trialDays])
	interface MenuItemsProps {
		to: string
		open?: boolean
		title: string
		linkActive?: string
		child?: boolean
		clickableCollapse?: boolean
		startIcon?: ReactNode
	}

	const RenderTrail = () => {
		if (!showTrialTip) return null
		return (
			<Box
				sx={{
					backgroundColor: isThereeDaysOrLessLeft
						? "rgba(254, 218, 21, 1)"
						: "rgba(19, 0, 128, 1)",
					width: "100%",
					height: "55px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					paddingX: isPhone ? 1 : 5,
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					{isThereeDaysOrLessLeft ? <BlackInfo /> : <Trail />}
					<Box
						sx={{
							color: isThereeDaysOrLessLeft ? "rgba(15, 23, 42, 1)" : "white",
							fontWeight: 700,
							fontSize: isPhone ? "10px" : "14px",
							marginX: isPhone ? 0 : 0.5,
						}}
					>
						{t("trial.YOU_ARE_IN_TRIAL")}
						<span
							style={{
								color: isThereeDaysOrLessLeft
									? "rgba(15, 23, 42, 1)"
									: "rgba(48, 135, 233, 1)",
							}}
						>
							{" "}
							{`${user?.trialDays}  ${t("trial.DAYS")}`}
						</span>
						{t("trial.TO_END")}
					</Box>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Box
						onClick={() =>
							true ? navigate("/subscribe") : setShowSupscriptionModal(true)
						}
						sx={{
							backgroundColor: "rgba(91, 63, 255, 1)",
							width: "141px",
							height: "31px",
							borderRadius: "20px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: language == "en" ? "12px" : "14px",
							color: "rgba(234, 237, 241, 1)",
							fontWeight: 400,
							cursor: "pointer",
						}}
					>
						{t("trial.ACTIVATE_NOW")}
					</Box>
					<Box
						onClick={() => setShowTrialTip(false)}
						sx={{ cursor: "pointer", marginX: 2, mt: 1 }}
					>
						{isThereeDaysOrLessLeft ? <BlackCloseIcon /> : <CloseIcon />}
					</Box>
				</Box>
			</Box>
		)
	}
	const breadcrumbNameMap: { [key: string]: string } = {
		"/dashboard/listing": "Listing",
		"/inbox/important": "Important",
		"/trash": "Trash",
		"/spam": "Spam",
		"/drafts": "Drafts",
	}

	const variantLink = (link?: string): "text" | "contained" =>
		link === active ? "contained" : "text"
	const childVariantLink = (link?: string): "primary" | "inherit" =>
		link === subActive ? "primary" : "inherit"

	const MenuItemsLink: FC<MenuItemsProps> = ({
		to,
		open,
		title,
		child,
		linkActive,
		startIcon,
		clickableCollapse = false,
	}) => {
		let Wrapper = ({ children }: { children: ReactNode }) => (
			<NavLink
				to={to}
				style={{
					textDecoration: "none",
					marginTop: linkActive === "settings" ? "auto" : "0",
					color: open == null ? "black" : "inherit",
				}}
			>
				{children}
			</NavLink>
		)
		let expandIcon = null
		if (open != null) {
			expandIcon = open ? <ExpandLess /> : <ExpandMore />
			Wrapper = ({ children }) => (
				<Box
					onClick={() => {
						setMenuOpen(to === menuOpen ? undefined : to)
						if (clickableCollapse) {
							navigate(`/${to}`)
						}
					}}
				>
					{children}
				</Box>
			)
		}
		return (
			<Wrapper>
				<Button
					startIcon={startIcon}
					endIcon={expandIcon}
					sx={{
						display: "flex",
						justifyContent: "start",
						textAlign: "start",
						padding: 1.25,
						gap: 2,
						my: 0.5,
						".MuiButton-endIcon": {
							marginInlineStart: "auto",
						},
					}}
					fullWidth
					variant={variantLink(linkActive)}
					color={child ? childVariantLink(linkActive) : "primary"}
				>
					{title}
				</Button>
			</Wrapper>
		)
	}

	const ChangeLang = () => {
		return (
			<IconButton
				size='large'
				onClick={() => changeLanguage(language === "en" ? "ar" : "en")}
			>
				<Typography color={"primary"} width={24} height={24}>
					{language === "en" ? "ع" : "En"}
				</Typography>
			</IconButton>
		)
	}
	const RenderDrawer = () => {
		const { isPhone } = useBreakPoinst()
		const isSegmgntUser = getUserInfo()?.isHasUAT ?? false

		return (
			<div className={"flex flex-col px-4 min-h-full pb-12 overflow-y-auto"}>
				<Box
					component='img'
					src={Logo}
					width='150px'
					sx={{ margin: 2, mb: 6, objectFit: "contain" }}
				/>
				<MenuItemsLink
					to='/calendar'
					startIcon={<CalendarIcon />}
					linkActive={"calendar"}
					title={t("DASHBOARD.CALENDAR")}
				/>
				<MenuItemsLink
					to='properties-management'
					startIcon={<ListIcon />}
					open={menuOpen === "properties-management"}
					linkActive={"properties-management"}
					title={t("PROPERTIES_MANAGEMENT.TITLE")}
					clickableCollapse
				/>
				<Collapse
					component={Box}
					sx={{ marginInlineStart: 2, marginBottom: 5 }}
					in={menuOpen === "properties-management"}
					timeout={10}
					unmountOnExit
				>
					{!disabledFeatures.category && <MenuItemsLink
						to='/category-management'
						linkActive={"category-management"}
						title={t("CATEGORYMANAGER.TITLE")}
						child
					/>}
				</Collapse>

				<MenuItemsLink
					to='locks-management/control'
					open={menuOpen === "locks-management/control"}
					linkActive={"locks-management"}
					startIcon={<KeyIcon />}
					title={t("LOCKS.TITLE")}
					clickableCollapse
				/>
				<Collapse
					component={Box}
					sx={{ marginInlineStart: 2, marginBottom: 5 }}
					in={menuOpen === "locks-management/control"}
					timeout={10}
					unmountOnExit
				>
					{/* <MenuItemsLink linkActive={'control'} title={t('LOCK.CONTROL')} to='/locks-management/control' child /> */}
					<MenuItemsLink
						linkActive={"listing"}
						title={t("DASHBOARD.LIST")}
						to='/locks-management/listing'
						child
					/>
				</Collapse>

				<MenuItemsLink
					to='finance/accounting'
					startIcon={<CreditIcon />}
					open={menuOpen === "finance"}
					title={t("FINANCE.TITLE")}
					linkActive={"finance"}
					clickableCollapse
				/>
				
				<MenuItemsLink
					to='/messaging'
					startIcon={<MailOutline />}
					linkActive={"messaging"}
					title={t("MESSAGING.TITLE")}
				/>
				{isSegmgntUser ? <MenuItemsLink
					to='/owner-management'
					startIcon={<PeopleAltOutlined />}
					linkActive={"owner-management"}
					title={t('OwnerManagement.Title')}
				/>:null}
				{!disabledFeatures.managePlan && (
					<MenuItemsLink
						to='/manage-plan'
						startIcon={<CardMembershipIcon />}
						linkActive={"manage-plan"}
						title={t("MANAGE_PLAN.TITLE")}
					/>
				)}
				{!disabledFeatures.dashboard && (
					<MenuItemsLink
						to='/dashboard'
						startIcon={<BarChartIcon />}
						linkActive={"dashboard"}
						title={t("DASHBOARD.TITLE")}
					/>
				)}
				<MenuItemsLink
					to='/reservation-management'
					startIcon={<ReserveIcon />}
					linkActive={"reservation-management"}
					title={t("RESERVATIONLIST")}
				/>
				<MenuItemsLink
					to='/guest-management'
					startIcon={<ReserveIcon />}
					linkActive={"guest-management"}
					title={t("GUESTMANAGEMENT")}
				/>
				{/* ----------------------------------- DM ----------------------------------- */}
				{!disabledFeatures.dm && <MenuItemsLink
					to='/direct-messages'
					startIcon={<MailOutline />}
					linkActive={"direct-messages"}
					title={t("DM.MESSAGES")}
				/>}
				{/* ----------------------------------- DM ----------------------------------- */}
				<Box sx={{ mt: isPhone ? 0 : "auto" }}>
					<MenuItemsLink
						to='/control-center'
						linkActive={"control-center"}
						startIcon={<TuneIcon />}
						title={t("CONTROL.TITLE")}
					/>
					<MenuItemsLink
						to='/settings'
						linkActive={"settings"}
						startIcon={<SettingsIcon />}
						title={t("SETTINGS")}
					/>
					<Button
						onClick={logout}
						sx={{ justifyContent: "start", padding: 1.25, gap: 2, my: 0.5 }}
						variant={"text"}
						color={"error"}
						startIcon={<LogoutIcon />}
					>
						{t("SIGN_OUT")}
					</Button>
				</Box>
			</div>
		)
	}

	return (
		<Box sx={{ display: "flex", minHeight: "100vh" }}>
			<Drawer
				variant='temporary'
				open={mobileOpen}
				anchor={language === "ar" ? "right" : "left"}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
				}}
			>
				{RenderDrawer()}
			</Drawer>
			<Drawer
				variant='permanent'
				anchor={language === "ar" ? "right" : "left"}
				sx={{
					borderColor: "transparent !important",
					display: { xs: "none", sm: "block" },
					"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
				}}
				open
			>
				{RenderDrawer()}
			</Drawer>
			<Box
				component='main'
				position={"relative"}
				paddingInlineStart={{ sm: `${drawerWidth}px` }}
				sx={{
					backgroundColor: "#f6f6f8",
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
				}}
			>
				{RenderTrail()}
				<Toolbar sx={{ backgroundColor: "white" }}>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							justifyContent: { xs: "space-between", sm: "flex-end" },
							gap: 2,
						}}
					>
						<ChangeLang />
						<IconButton
							onClick={() => setMobileOpen(true)}
							sx={{ display: { xs: "flex", sm: "none" } }}
							size='large'
						>
							<MoreIcon />
						</IconButton>
					</Box>
				</Toolbar>
				<Box
					sx={{ display: "flex", flexGrow: 1, flexDirection: "column", p: 3 }}
				>
					<Suspense fallback={<></>}>
						<Outlet />
					</Suspense>
				</Box>
			</Box>
			<SubscriptModal
				open={showSubscibeModal}
				onClose={() => { }}
				onSupscribe={() => {
					setShowSubscibeModal(true)
					//  setShowSupscriptionModal(true)
					navigate("/subscribe")
				}}
			/>
			<SupscriptionPlaneModal
				OnSubscriptionSelected={(pack: number) => {
					setShowSupscriptionModal(false)
					navigate({
						pathname: "/register",
						search: createSearchParams({
							package: pack.toString(),
							id: window.localStorage.getItem("userId") ?? "",
						}).toString(),
					})
				}}
				open={showSupscriptionModal}
				handleClose={() => setShowSupscriptionModal(false)}
			/>
			<ThreeDaysToSupscripeModal
				onSupscribe={() => {
					setShowThreeDaysModal(false)
					//setShowSupscriptionModal(true)
					navigate("/subscribe")
				}}
				open={showThreeDaysModal}
				handleClose={() => setShowThreeDaysModal(false)}
			/>
		</Box>
	)
}

export default Layout
import FullCalendar from "@fullcalendar/react"
import { MutableRefObject } from "react"
import { GoToNextWrapper } from "./styles"
import { GrPrevious } from "react-icons/gr"

interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
}
export default function GoToNext({ calendarRef }: IProps) {
	const goToNext = () => {
		const calendarApi = calendarRef.current?.getApi()
		if (calendarApi) {
			calendarApi.next() // Navigate to the next period
		}
	}
	return (
		<GoToNextWrapper>
			<button onClick={goToNext}>
				<GrPrevious />
			</button>
		</GoToNextWrapper>
	)
}

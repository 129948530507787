import { DateRangeInput } from "@fullcalendar/core"

// Function to determine the visible range of the current week (Monday to Sunday)
export const getCurrentWeekVisibleRange = (): DateRangeInput => {
	const startOfWeek = new Date() // Get current date
	startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1) // Set to Monday
	const endOfWeek = new Date(startOfWeek)
	endOfWeek.setDate(startOfWeek.getDate() + 6) // Set to Sunday

	return { start: startOfWeek, end: endOfWeek }
}

import { EventContentWrapper } from "./styles"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"

interface IProps {
	channal: "Airbnb" | "GatherIn" | "Booking" | "Agoda" | "Msool"
	title: string
	status: "yellow" | "gray" | "green"
}
interface IChannals {
	Airbnb: JSX.Element
	GatherIn: JSX.Element
	Booking: JSX.Element
	Agoda: JSX.Element
	Msool: JSX.Element
}
const channals: IChannals = {
	Airbnb: <AirbnbIcon />,
	GatherIn: <GathernIcon />,
	Booking: <BookingIcon />,
	Agoda: <ChannelAgoda />,
	Msool: <MsoolIcon />,
}
export default function EventContent({ channal, title, status }: IProps) {
	return (
		<EventContentWrapper status={status}>
			<div className='custom-event'>
				<span className='containerIcon'>{channals[channal]}</span>
				<span className='containerContent'>{title}</span>
			</div>
		</EventContentWrapper>
	)
}
